<template>
  <div class="about">
    <h1>SST Debugger v{{ appVersion }}</h1>
    <p>
      &copy; {{ copyrightYears }} Semantic STEP Technology GmbH, Germany & DCT
      Co., Ltd. Tianjin, China
    </p>
    <p>Build id: {{ buildId }}</p>
  </div>
</template>
<script>
import { version } from "../../package";
export default {
  data: () => ({
    appVersion: version,
    copyrightYears: "2021",
    buildId: process.env.VUE_APP_BUILD_ID
      ? process.env.VUE_APP_BUILD_ID
      : "unknown",
  }),
};
</script>